<template>
  <v-card>
    <v-card-title style="gap: 8px" class="align-center flex-wrap">
      <v-icon>mdi-card-account-details</v-icon>
      <span class="text-uppercase text-subtitle-1 font-weight-bold"
        >Información personal</span
      >
    </v-card-title>
    <v-divider />
    <v-card-text>
      <v-row no-gutters>
        <v-col class="pr-sm-4 px-0" cols="12" sm="6">
          <v-select
            :disabled="modo === 'creacion'"
            :error-messages="tipoSolicitudError"
            :items="ctlTipoSolicitud"
            @blur="$v.tipoSolicitud.$touch()"
            @input="$v.tipoSolicitud.$touch()"
            clearable
            item-text="nombre"
            label="Tipo de solicitud *"
            no-data-text="Sin datos"
            outlined
            placeholder="Seleccione"
            v-model="tipoSolicitud"
            return-object
            @change="cleanAllInputs"
          />
        </v-col>
        <v-col cols="0" sm="6" />
        <v-col class="pr-sm-4 px-0" cols="12" sm="6">
          <v-autocomplete
            :error-messages="nacionalidadError"
            :items="ctlNacionalidad"
            @blur="$v.nacionalidad.$touch()"
            @input="$v.nacionalidad.$touch()"
            clearable
            item-text="nombre"
            label="Nacionalidad *"
            no-data-text="Sin datos"
            outlined
            placeholder="Seleccione"
            v-model="nacionalidad"
            return-object
          />
        </v-col>
        <v-col cols="0" sm="6" />
        <v-col class="pr-sm-4 px-0" cols="12" sm="6">
          <v-text-field
            :disabled="nacionalidad == null"
            :error-messages="numeroDocumentoError"
            :maxLength="docMxLength"
            @blur="$v.numeroDocumento.$touch()"
            @click:clear="cleanNames()"
            @input="$v.numeroDocumento.$touch()"
            @keydown.enter.prevent="consultarUsuario"
            clearable
            label="Número de documento *"
            outlined
            required
            v-mask="mask"
            v-model="numeroDocumento"
          />
        </v-col>
        <v-col class="pl-sm-4 px-0" cols="12" sm="6">
          <v-btn
            color="btnGreen"
            :block="$vuetify.breakpoint.xs"
            class="bgWhite--text text-capitalize"
            @click="consultarUsuario"
            :disabled="$v.numeroDocumento.$invalid"
          >
            Verificar
          </v-btn>
        </v-col>
        <v-col class="pr-sm-4 px-0" cols="12" sm="6">
          <v-text-field
            :clearable="!verifyNacionalidad"
            :error-messages="primerNombreError"
            :disabled="nacionalidad ? nacionalidad.id === 1 : true"
            :readonly="verifyNacionalidad"
            @blur="$v.primerNombre.$touch()"
            @input="$v.primerNombre.$touch()"
            label="Primer nombre"
            maxLength="50"
            outlined
            v-model.trim="primerNombre"
          />
        </v-col>
        <v-col class="pl-sm-4 px-0" cols="12" sm="6">
          <v-text-field
            :clearable="!verifyNacionalidad"
            :error-messages="segundoNombreError"
            :disabled="nacionalidad ? nacionalidad.id === 1 : true"
            :readonly="verifyNacionalidad"
            @blur="$v.segundoNombre.$touch()"
            @input="$v.segundoNombre.$touch()"
            label="Segundo nombre"
            maxLength="50"
            outlined
            v-model.trim="segundoNombre"
          />
        </v-col>
        <v-col class="pr-sm-4 px-0" cols="12" sm="6">
          <v-text-field
            :clearable="!verifyNacionalidad"
            :error-messages="primerApellidoError"
            :disabled="nacionalidad ? nacionalidad.id === 1 : true"
            :readonly="verifyNacionalidad"
            @blur="$v.primerApellido.$touch()"
            @input="$v.primerApellido.$touch()"
            label="Primer apellido"
            maxLength="50"
            outlined
            v-model.trim="primerApellido"
          />
        </v-col>
        <v-col class="pl-sm-4 px-0" cols="12" sm="6">
          <v-text-field
            :clearable="!verifyNacionalidad"
            :error-messages="segundoApellidoError"
            :disabled="nacionalidad ? nacionalidad.id === 1 : true"
            :readonly="verifyNacionalidad"
            @blur="$v.segundoApellido.$touch()"
            @input="$v.segundoApellido.$touch()"
            label="Segundo apellido"
            maxLength="50"
            outlined
            v-model.trim="segundoApellido"
          />
        </v-col>
        <v-col class="pr-sm-4 px-0" cols="12" sm="6">
          <v-text-field
            :clearable="!verifyNacionalidad"
            :error-messages="apellidoCasadaError"
            :disabled="nacionalidad ? nacionalidad.id === 1 : true"
            :readonly="verifyNacionalidad"
            @blur="$v.apellidoCasada.$touch()"
            @input="$v.apellidoCasada.$touch()"
            label="Apellido de casada"
            maxLength="50"
            outlined
            v-if="showInput"
            v-model.trim="apellidoCasada"
          />
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { required, helpers } from "vuelidate/lib/validators";
import { mapMutations } from "vuex";
import moment from "moment";
import { isNil } from "lodash";

const letter = helpers.regex("letter", /^[a-zA-ZÀ-ÿ\u00f1\u00d1'. ]*$/);

function typeDui(value) {
  if (value && this.nacionalidad?.id_tipo_documento) {
    const regexDocument = /^[0-9-]*$/;
    if (this.nacionalidad?.id_tipo_documento === 1) {
      return this.isDui(value);
    } else if (this.nacionalidad?.id_tipo_documento === 2) {
      return this.isNit(value);
    } else {
      return regexDocument.test(value);
    }
  } else {
    return true;
  }
}

function validCantidadNumero(value) {
  if (!value) return true;
  if (
    this.nacionalidad?.id_tipo_documento !== 1 ||
    this.nacionalidad?.id_tipo_documento !== 2
  ) {
    return value.length > 1;
  }
}
export default {
  name: "FormInformacionGeneralComponent",
  emits: ["on-verificacion-usuario"],
  props: {
    modo: { type: String },
  },
  validations() {
    const duiExcluidosSegundoNombre = ["05618219-3"];

    return {
      tipoSolicitud: {
        required,
      },
      nacionalidad: {
        required,
      },
      numeroDocumento: {
        required,
        typeDui,
        validCantidadNumero,
      },
      primerNombre: {
        required,
        letter,
      },
      segundoNombre: {
        ...(duiExcluidosSegundoNombre.includes(this.numeroDocumento)
          ? {}
          : { letter }),
      },
      primerApellido: {
        required,
        ...(this.numeroDocumento !== "02430189-2" && { letter }),
      },
      segundoApellido: {
        letter,
      },
      apellidoCasada: {
        letter,
      },
    };
  },
  data: () => ({
    dataVerifyUser: false,
    apellidoCasada: null,
    nacionalidad: null,
    numeroDocumento: null,
    primerApellido: null,
    primerNombre: null,
    segundoApellido: null,
    segundoNombre: null,
    tipoSolicitud: null,
    mask: "########-#",
    docMxLength: null,
    showInput: false,
    ctlNacionalidad: [],
    ctlTipoSolicitud: [],
  }),
  computed: {
    verifyNacionalidad() {
      if (
        !this.$v.nacionalidad.$invalid &&
        this.nacionalidad?.id_tipo_documento != 1
      ) {
        this.showInput = true;
        return false;
      } else {
        return true;
      }
    },
    tipoSolicitudError() {
      const errors = [];
      if (!this.$v.tipoSolicitud.$dirty) return errors;
      !this.$v.tipoSolicitud.required &&
        errors.push("Tipo de solicitud es requerido");
      return errors;
    },
    nacionalidadError() {
      const errors = [];
      if (!this.$v.nacionalidad.$dirty) return errors;
      !this.$v.nacionalidad.required &&
        errors.push("Nacionalidad es requerido");
      return errors;
    },
    numeroDocumentoError() {
      const errors = [];
      if (!this.$v.numeroDocumento.$dirty) return errors;
      !this.$v.numeroDocumento.required &&
        errors.push("Número de documento es requerido");
      !this.$v.numeroDocumento.typeDui &&
        errors.push("Número de documento no es válido");
      !this.$v.numeroDocumento.validCantidadNumero &&
        errors.push("Longitud del número de documento debe ser mayor a 1");
      return errors;
    },
    primerNombreError() {
      const errors = [];
      if (!this.$v.primerNombre.$dirty) return errors;
      !this.$v.primerNombre.required &&
        errors.push("Primer nombre es requerido");
      !this.$v.primerNombre.letter && errors.push("Primer nombre no es válido");
      return errors;
    },
    segundoNombreError() {
      const errors = [];
      if (!this.$v.segundoNombre.$dirty) return errors;
      !this.$v.segundoNombre.letter &&
        errors.push("Segundo nombre no es válido");
      return errors;
    },
    primerApellidoError() {
      const errors = [];
      if (!this.$v.primerApellido.$dirty) return errors;
      !this.$v.primerApellido.required &&
        errors.push("Primer apellido es requerido");
      !this.$v.primerApellido.letter &&
        errors.push("Primer apellido no es válido");
      return errors;
    },
    segundoApellidoError() {
      const errors = [];
      if (!this.$v.segundoApellido.$dirty) return errors;
      !this.$v.segundoApellido.letter &&
        errors.push("Segundo apellido no es válido");
      return errors;
    },
    apellidoCasadaError() {
      const errors = [];
      if (!this.$v.apellidoCasada.$dirty) return errors;
      !this.$v.apellidoCasada.letter &&
        errors.push("Apellido de casada no es válido");
      return errors;
    },
  },
  methods: {
    ...mapMutations("solicitudCuenta", [
      "resetState",
      "setFormInfoGeneral",
      "setFormMainCuenta",
      "setIdUsuario",
    ]),
    saveData() {
      this.setFormInfoGeneral({
        apellidoCasada: this.apellidoCasada,
        nacionalidad: this.nacionalidad,
        numeroDocumento: this.numeroDocumento,
        primerApellido: this.primerApellido,
        primerNombre: this.primerNombre,
        segundoApellido: this.segundoApellido,
        segundoNombre: this.segundoNombre,
        tipoSolicitud: this.tipoSolicitud,
      });
    },
    cleanNames() {
      this.primerNombre = null;
      this.segundoNombre = null;
      this.primerApellido = null;
      this.segundoApellido = null;
      this.apellidoCasada = null;
    },
    cleanAllInputs() {
      this.cleanNames();
      this.numeroDocumento = null;
    },
    async consultarUsuario() {
      if (this.numeroDocumento != null && this.nacionalidad != null) {
        const validarSolicitudUnica =
          await this.services.SolicitudesUsuarios.listarSolicitudes({
            numero_documento: this.numeroDocumento,
            id_tipo_documento: this.nacionalidad?.id,
          });

        if (parseInt(validarSolicitudUnica?.data?.total) > 0) {
          this.temporalAlert({
            show: true,
            type: "warning",
            message:
              "Anteriormente se ha creado una solicitud para el documento ingresado",
          });
          return;
        }

        const response = await this.services.Usuarios.getUsuarios({
          numero_documento: this.numeroDocumento,
          id_tipo_documento: this.nacionalidad?.id,
          verificacion: true,
        });
        if (response) {
          if (this.tipoSolicitud?.id == 1) {
            if (
              response?.data?.length == 0 ||
              response?.data[0]?.id_usuario == null
            ) {
              this.temporalAlert({
                show: true,
                type: "info",
                message: "Documento apto para crear cuenta",
              });
              this.resetState();
              if (this.nacionalidad?.id_tipo_documento == 1) {
                const data = await this.consultarPersona();
                this.dataVerifyUser = data;
              } else {
                this.dataVerifyUser = true;
              }
            } else {
              this.temporalAlert({
                show: true,
                type: "error",
                message:
                  "El documento ingresado ya se encuentra registrado en el sistema",
              });
              this.dataVerifyUser = false;
            }
          } else if (this.tipoSolicitud?.id == 2) {
            if (
              response?.data?.length > 1 ||
              response?.data[1]?.id_usuario != null
            ) {
              this.temporalAlert({
                show: true,
                type: "error",
                message:
                  "El documento ingresado posee más de una cuenta asociada",
              });
              this.dataVerifyUser = false;
            } else if (
              response?.data?.length == 0 ||
              response?.data[0]?.id_usuario == null
            ) {
              this.temporalAlert({
                show: true,
                type: "error",
                message:
                  "El documento ingresado no corresponde a ningún usuario",
              });
              this.dataVerifyUser = false;
            } else {
              this.dataVerifyUser = true;
              this.temporalAlert({
                show: true,
                type: "info",
                message: "Documento registrado en el sistema",
              });

              if (response?.data[0]?.apellido_casada != null) {
                this.showInput = true;
                this.apellidoCasada = response?.data[0]?.apellido_casada;
              } else {
                this.showInput = false;
              }

              // setear datos de usuario en vista
              this.apellidoCasada = response?.data[0]?.apellido_casada;
              this.primerApellido = response?.data[0]?.primer_apellido;
              this.primerNombre = response?.data[0]?.primer_nombre;
              this.segundoApellido = response?.data[0]?.segundo_apellido;
              this.segundoNombre = response?.data[0]?.segundo_nombre;
              this.nacionalidad = response?.data[0]?.nacionalidad;
              this.setIdUsuario(response?.data[0]?.id_usuario);
              // setear datos de usuario en store
              this.setFormInfoGeneral({
                apellidoCasada: response?.data[0]?.apellido_casada,
                primerApellido: response?.data[0]?.primer_apellido,
                primerNombre: response?.data[0]?.primer_nombre,
                segundoApellido: response?.data[0]?.segundo_apellido,
                segundoNombre: response?.data[0]?.segundo_nombre,
                estadoUsuario: response?.data[0]?.estado_usuario,
                nacionalidad: response?.data[0]?.nacionalidad,
              });
              this.setFormMainCuenta({
                cargoUsuario: response?.data[0]?.cargo_funcional,
                stckUnidades: response?.data[0]?.unidades,
                divisionUsuario:
                  response?.data[0]?.unidades.length == 1
                    ? response?.data[0]?.unidades[0]
                    : null,
                emailUsuario: response?.data[0]?.correo_electronico,
                fechaCaducidadUsuario: moment(
                  response?.data[0]?.fecha_caducidad
                ).format("DD/MM/YYYY"),
                infoAdicionalUsuario: response?.data[0]?.informacion_adicional,
                profesionUsuario: response?.data[0]?.profesion,
                rolPerfilUsuario: response?.data[0]?.rol_perfil,
                telefonoUsuario: response?.data[0]?.telefono,
                urlDocumentoInstitucional:
                  response?.data[0]?.url_documento_cargo,
                detalleModificacionUsuario:
                  response?.data[0]?.observaciones_modificacion,
              });
            }
          }
        }
      }
    },
    async consultarPersona() {
      try {
        const response =
          await this.services.VerificarDocumento.verificarDocumento(
            this.numeroDocumento
          );
        if (response) {
          this.primerNombre = response?.data?.primer_nombre;
          this.segundoNombre = response?.data?.segundo_nombre;
          this.primerApellido = response?.data?.primer_apellido;
          this.segundoApellido = response?.data?.segundo_apellido;
          // BUG: el id_genero no se encuentra en la respuesta del servicio en su lugar se verifica si el valor de apellido_casada es null
          if (response?.data?.apellido_casada == null) {
            this.apellidoCasada = null;
            this.showInput = false;
          } else {
            this.showInput = true;
            this.apellidoCasada = response?.data?.apellido_casada;
          }

          return true;
        } else {
          this.temporalAlert({
            show: true,
            type: "error",
            message: "No se encontró información del documento ingresado",
          });

          return false;
        }
      } catch (error) {
        console.log(error);
      }
    },
    async fetchTipoSolicitud() {
      try {
        const response = await this.services.Usuarios.getTiposSolicitud();
        if (response) {
          this.ctlTipoSolicitud = response.data;
        }

        if (!isNil(this.modo)) {
          this.tipoSolicitud = response.data.find((tipo) => tipo.id === 1);
        }
      } catch (error) {
        console.log(error);
      }
    },
    async fetchNacionalidad() {
      try {
        const response =
          await this.services.IdentificadorFiscal.getIdentificadorFiscal();
        if (response) {
          this.ctlNacionalidad = response.data.filter((item) =>
            [1, 6].includes(item.id)
          );
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
  watch: {
    nacionalidad: function (val) {
      if (val?.id_tipo_documento === 1) {
        this.mask = "########-#";
        this.docMxLength = 10;
      } else if (val?.id_tipo_documento === 2) {
        this.mask = "####-######-###-#";
        this.docMxLength = 17;
      } else {
        this.mask = "";
        this.docMxLength = 15;
      }
    },
    numeroDocumento: function (val) {
      if (val == null || val == "") {
        this.cleanNames();
      }
    },
    dataVerifyUser(value) {
      this.$emit("on-verificacion-usuario", value);
    },
    modo: {
      handler(value) {
        if (value && value === "creacion") {
          this.tipoSolicitud = this.tipoSolicitud = this.ctlTipoSolicitud.find(
            (tipo) => tipo.id === 1
          );
        }
      },
      immediate: true,
    },
  },
  created() {
    this.fetchTipoSolicitud();
    this.fetchNacionalidad();
  },
};
</script>

<style></style>
